#photos {
  h2 {
    color:$c-white;

    span {
      margin-left: 153px;
      width: 807px;
    }
  }

  #photo-group {
    overflow: hidden;
  }
}

.white {
  color:$c-white;
}

.grid {
  max-width: 960px;

  /* clear fix */
  .grid:after {
    content: '';
    display: block;
    clear: both;
  }

  /* ---- .grid-item ---- */

  .grid-item {
    position: relative;
    float: left;
    width: 192px;
    height: 400px;
    margin: 5px auto;

    .grid-item__interior {
      padding: 10px;
      background:$c-white;
      height:100%;
      width:97%;

      img {
        width: 100%;
        max-height: 160px;
      }
    }
  }

  .grid-item > * {
   /* margin: 0;
    padding: 0;*/
  }



}