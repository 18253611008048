.__mTop {
	margin-top: $wideMargin;
}

.__mTop__wee {
	margin-top: $defaultMargin;
}

.__mTop__wide {
	margin-top: $wideMargin*2;
}

.__mTop__wider {
	margin-top: $wideMargin*3;
}

.__mBottom {
	margin-bottom: $wideMargin;
}

.__mBottom__wide {
	margin-bottom: $wideMargin*2;
}

.__mBottom__wider {
	margin-bottom: $wideMargin*3;
}

.__mV {
	margin-top: $wideMargin;
	margin-bottom: $wideMargin;
}

.__mV__wide {
	margin-top: $wideMargin*2;
	margin-bottom: $wideMargin*2;
}

.__mV__wee {
	margin-top: $defaultMargin;
	margin-bottom: $defaultMargin;
}

.__pTop {
	padding-top: $wideMargin;
}

.__pTop__wide {
	padding-top: $wideMargin*2;
}

.__pTop__wee {
	padding-top: $defaultMargin;
}

.__pBottom {
	padding-bottom: $wideMargin;
}

.__pBottom__wide {
	padding-bottom: $wideMargin*2;
}

.inline-block-item {
	display: inline-block;
}
.inline-item {
	display: inline;
}

.float__right {
	float:right;
}
.float__left {
	float:left;
}
