#reel {

  h2 {
    span {
      margin-left: 130px;
      width: 830px;
    }
  }

  .reel-row {
    margin-bottom:2em;
    padding: 20px 0 10px;
    color: $c-dark;
    text-shadow: 0 1px $c-white;

    .imageLoader {
      width:100%;
      margin-right: 20px;
      margin-bottom:20px;
      border: 8px solid $c-white;
      -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    }

    p {
      font-size: 1.1em;
    }
  }

}