.btn {
    cursor: pointer;
    display: inline-block;
    background-color: $c-gray;
    color: $c-white !important;
    font-weight: $fw-bold;
    line-height: 1;
    text-decoration: none;
    padding: $navMargin*1.2 $defaultMargin*.9;
    text-transform: uppercase;
    letter-spacing: 3px;

    &:hover {
      opacity:.6;
    }
}

label {
  position: relative;
  font-weight:700;
}

textarea,
input[type="text"],
input[type="email"] {
  margin-top:6px;
  width:100%;
  min-width:400px;
  padding:8px;
  background: $c-lightGray;

  @include mqSmallerThan(tabletLandscape) {
    min-width:100%;
  }
}

textarea {
  width:104%;
  height:120px;
}

.g-recaptcha {
  margin-top:6px;
}

ul.errors {
  display: none;
}
.has-error .form-control,
.has-error .custom-file-label {
  border-color: #d00 !important;
}
.has-error .form-check-label {
  color: #d00
}
.has-error ul.errors {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: inherit;
}
.has-error ul.errors > li {
  font-size: 80%;
  color: #d00;
  margin-top: .25rem;
}

.alert {
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  position: fixed;
  top: 50px;
  width: 100%;
  z-index: 999;
  text-align: center;
  transition: opacity .3s;
}

#contact-form {
  .alert {
    top:0;
    position: relative;
  }
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}