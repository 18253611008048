/*
 * Super-SASSy functions!
 */
/*
 * Colors
 */
/*
 * Share colors
 */
/*
 * Opacity
 */
/*
 * Timing
 */
/*
 * Typography
 */
/*
 * Spacing
 */
/*
 * Flexbox Grid
 */
/*
 * Super-SASSy mixins
 */
/*
 * Value/unit breakpoints
 */
/*
 * Named breakpoints
 */
/**
Scales any property.
Usage example: `@include scaling('height', 1, 120);`
@copyright: Aaron Waldon <aaron@causingeffect.com>, January 2017
 */
/**
Scaling font-size.
Usage example: `@include scalingFs(100, 600);`
@copyright: Aaron Waldon <aaron@causingeffect.com>, January 2017
 */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
* {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*
 * Base styles
 */
html {
  overflow-y: scroll; }
  html.html--noScroll {
    overflow: hidden; }

.html--noScroll body {
  overflow-y: hidden; }

::-moz-selection {
  color: white;
  background: black;
  text-shadow: none; }

::selection {
  color: white;
  background: black;
  text-shadow: none; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

/*
 * Starter styles
 */
*,
*:after,
*:before {
  box-sizing: border-box; }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }

body {
  position: relative;
  margin: 0 auto;
  overflow-x: hidden;
  display: block; }

p, ul, ol, blockquote {
  position: relative; }
  p + p, ul + p, ol + p, blockquote + p, p + ul, ul + ul, ol + ul, blockquote + ul, p + ol, ul + ol, ol + ol, blockquote + ol, p + blockquote, ul + blockquote, ol + blockquote, blockquote + blockquote {
    margin-top: 0.875rem; }

li {
  position: relative; }
  li + li {
    margin-top: 0.875rem; }

a {
  text-decoration: underline;
  color: #191a15; }
  a:hover {
    text-decoration: none;
    /*opacity: $smokeOpacity;*/ }
    a:hover img {
      opacity: 0.7; }

i {
  font-style: normal; }

button {
  border: none;
  background: transparent; }

input[type="search"] {
  box-sizing: border-box; }

/*
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
  display: block;
  position: relative; }

.img__stretch {
  width: 100%; }

.img__circle {
  border-radius: 50%; }

/*
 * Transitions
 */
* {
  transition: all 0s linear 0s, opacity 0.5s ease-in-out 0s, background-position 0.5s ease-in-out 0s, color 0.2s linear 0s, background-color 0.2s linear 0s, font-size 0s linear 0s, line-height 0s linear 0s; }

.__limitedTransitions, .__limitedTransitions *, html {
  transition: color 0.2s ease 0s, background-color 0.2s ease 0s; }

/*
 * Helpers
 */
.clearfix {
  *transform: scale(1); }
  .clearfix:before, .clearfix:after {
    content: " ";
    display: table; }
  .clearfix:after {
    clear: both; }

.mb {
  overflow: hidden;
  _overflow: visible;
  transform: scale(1);
  transform-origin: 0 0; }

.mb_body {
  margin-top: 0;
  overflow: hidden;
  _overflow: visible;
  transform: scale(1);
  transform-origin: 0 0; }

.mb .mb_media {
  float: left;
  margin-right: 0.875rem;
  margin-top: 0; }

.mb .mb_media__alt {
  float: right;
  margin-left: 0.875rem;
  margin-top: 0; }

.mb .mb_media img, .mb .mb_media__alt img {
  display: block; }

.hidden {
  display: none !important;
  visibility: hidden; }

.visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute; }

.invisible {
  visibility: hidden; }

.hide {
  display: none; }

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .hideOnPhone {
    display: none !important;
    visibility: hidden; } }

.onlyOnPhone {
  display: none;
  visibility: hidden; }
  @media only screen and (min-width: 0px) and (max-width: 600px) {
    .onlyOnPhone {
      display: inherit;
      visibility: inherit; } }

.tabletAndSmaller {
  display: none;
  visibility: hidden; }
  @media only screen and (max-width: 901px) {
    .tabletAndSmaller {
      display: inherit;
      visibility: inherit; } }

.page {
  z-index: 0; }

.content {
  width: 100%;
  max-width: 960px;
  margin: 0 auto 30px;
  z-index: 10; }

.section {
  border-top: 1px solid #191a15;
  clear: both;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  color: #191a15;
  padding: 90px 20px 0; }
  .section h2 {
    position: relative;
    color: #191a15;
    text-transform: uppercase;
    font-size: 53px;
    font-size: 3.3125rem;
    font-weight: 600;
    text-shadow: 0 1px #d9dbd0;
    width: 100%;
    margin-bottom: 20px; }
    .section h2 span {
      background: url(/assets/sysimages/line1.png) repeat-x;
      height: 8px;
      float: left;
      position: relative;
      top: -32px; }
  .section p {
    font-size: 1.3em;
    line-height: 1.4em;
    margin: 14px 0; }
  .section a {
    position: relative; }

#social {
  position: fixed;
  bottom: 0;
  right: 10%;
  height: 58px;
  background-color: #191a15;
  background-color: rgba(0, 0, 0, 0.4);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin: 0;
  padding: 4px 8px 10px; }
  #social ul {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0; }
    #social ul li {
      float: left;
      padding: 5px; }
      #social ul li.social {
        height: 36px;
        width: 36px;
        top: 4px;
        position: relative;
        margin-left: 5px; }
        #social ul li.social a {
          display: block;
          width: 34px;
          height: 34px; }
          #social ul li.social a svg {
            width: 30px;
            height: 30px; }
        #social ul li.social a#facebook {
          background-position: 0 0; }
        #social ul li.social a#twitter {
          background-position: -34px 0; }
        #social ul li.social a#youtube {
          background-position: -68px 0; }
        #social ul li.social:hover {
          opacity: 0.6; }
    #social ul li + li {
      margin-top: 0; }

.videoThumb {
  position: relative;
  display: inline-block;
  width: 100%; }

.start-btn {
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.4; }
  .start-btn svg {
    height: 50px;
    width: 50px;
    fill: #fff; }

.email-icon svg {
  width: 25px;
  height: 25px;
  margin-top: -6px; }

.email-icon-white svg {
  fill: #fff;
  margin-right: 5px; }

#nav-container {
  z-index: 10;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  background-color: #191a15;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4); }
  #nav-container nav {
    position: relative;
    max-width: 960px;
    width: 100%;
    margin: 14px auto 0; }
    #nav-container nav ul#mainNav {
      list-style: none;
      display: inline; }
      #nav-container nav ul#mainNav li {
        float: left;
        font-size: 26px;
        font-size: 1.625rem;
        margin: 2px 14px 0;
        text-transform: uppercase;
        font-weight: 600; }
        #nav-container nav ul#mainNav li a {
          color: #fff;
          text-decoration: none;
          outline: none; }
          #nav-container nav ul#mainNav li a:hover {
            color: #a0a489;
            text-decoration: none; }
          #nav-container nav ul#mainNav li a.active {
            color: #dde5ba; }
        @media only screen and (min-width: 1018px) {
          #nav-container nav ul#mainNav li:first-child {
            margin-left: 0; } }
      @media only screen and (max-width: 901px) {
        #nav-container nav ul#mainNav {
          left: -999em;
          position: absolute;
          top: 36px;
          background-color: rgba(0, 0, 0, 0.8);
          width: 100%;
          padding: 20px 20px; }
          #nav-container nav ul#mainNav li {
            float: none;
            padding: 5px;
            margin: 0; } }
      #nav-container nav ul#mainNav.menu-active {
        left: 0; }
    #nav-container nav #logo {
      background: url(/assets/sysimages/bkgrd-sub-header.png) no-repeat;
      width: 250px;
      height: 50px;
      position: absolute;
      top: -14px;
      right: 0;
      text-align: center;
      opacity: 0;
      transition: opacity 0.5s; }
      @media only screen and (max-width: 901px) {
        #nav-container nav #logo {
          right: 30px; } }
      #nav-container nav #logo a {
        line-height: 1.8em;
        height: 50px;
        font-size: 28px;
        font-size: 1.75rem;
        font-weight: 800;
        color: #fff;
        text-decoration: none;
        outline: none; }
        #nav-container nav #logo a:hover {
          color: #a0a489;
          text-decoration: none; }
    #nav-container nav .mobileNav--toggle {
      display: none;
      position: absolute;
      top: -18px;
      margin: 0 auto;
      text-align: center;
      cursor: pointer;
      width: 60px;
      height: 60px;
      transition: transform 0.2s ease-in-out; }
      #nav-container nav .mobileNav--toggle a {
        display: block;
        position: absolute;
        width: 100%;
        height: 60px;
        padding-top: 7px;
        transition: transform 0.2s ease-in-out; }
      @media only screen and (max-width: 901px) {
        #nav-container nav .mobileNav--toggle {
          display: block; } }
    #nav-container nav #mobileNav--toggleIcon {
      padding-left: 13px;
      padding-top: 28px; }
      #nav-container nav #mobileNav--toggleIcon span, #nav-container nav #mobileNav--toggleIcon span:before, #nav-container nav #mobileNav--toggleIcon span:after {
        cursor: pointer;
        border-radius: 1px;
        height: 3px;
        width: 35px;
        background: #fff;
        position: absolute;
        display: block;
        content: ''; }
      #nav-container nav #mobileNav--toggleIcon span:before {
        top: -10px; }
      #nav-container nav #mobileNav--toggleIcon span:after {
        bottom: -10px; }
    #nav-container nav #mobileNav--toggleIcon span, #nav-container nav #mobileNav--toggleIcon span:before, #nav-container nav #mobileNav--toggleIcon span:after {
      transition: all 200ms ease-in-out; }
    #nav-container nav #mobileNav--toggleIcon.nav-active span {
      background-color: transparent; }
    #nav-container nav #mobileNav--toggleIcon.nav-active span:before, #nav-container nav #mobileNav--toggleIcon.nav-active span:after {
      top: 0; }
    #nav-container nav #mobileNav--toggleIcon.nav-active span:before {
      transform: rotate(45deg); }
    #nav-container nav #mobileNav--toggleIcon.nav-active span:after {
      transform: rotate(-45deg); }

#nav-container nav #logo a, #home #home-page #home-logo h1, #home #home-page #home-logo h2, .interior h1, .cursive {
  font-family: 'bitter', serif; }

#nav-container nav ul#mainNav li, h2, h4, #home #home-page .awards .recog, #home #home-page .awards .title, #home #home-page .awards .role, #home #home-page .awards .year, #resume .resume-sidebar h5, #resume .resume-sidebar h6, .interior h3, .interior .button, .serif {
  font-family: 'alternate-gothic-atf', sans-serif; }

body, h3, .sansSerif {
  font-family: 'open-sans', sans-serif; }

em, .em {
  font-style: italic; }

strong, .strong {
  font-weight: bold; }

h2 {
  font-weight: 800;
  font-size: 53px;
  font-size: 3.3125rem; }

h3 {
  color: #191a15;
  font-size: 35px;
  font-size: 2.1875rem;
  font-weight: 600;
  margin-bottom: 10px; }

h4 {
  color: #191a15;
  font-size: 28px;
  font-size: 1.75rem;
  font-weight: 800;
  text-transform: uppercase;
  margin: 30px 0 0;
  padding: 0; }

/*
 * Background image tile
 */
.imageLoader {
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 0); }

.imageLoader img {
  display: block;
  width: 100%; }

.imageLoader_image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out; }

.imageLoader_image.lazyloaded {
  opacity: 1; }

a:hover .imageLoader_previewImage {
  opacity: 0; }

a:hover .imageLoader_image.lazyloaded {
  opacity: 0.7 !important; }

.imageLoader_previewImage {
  -webkit-filter: blur(5px);
  filter: blur(5px); }

/*
 * Background image tile
 */
.imageBackgroundLoader {
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 0; }
  .imageBackgroundLoader .imageBackgroundLoader_previewImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-color: black;
    z-index: 0;
    -webkit-filter: blur(5px);
    filter: blur(5px);
    transition: opacity 1s ease-in-out; }
  .imageBackgroundLoader.lazyloaded .imageBackgroundLoader_previewImage {
    opacity: 0; }

.btn {
  cursor: pointer;
  display: inline-block;
  background-color: #333;
  color: #fff !important;
  font-weight: 900;
  line-height: 1;
  text-decoration: none;
  padding: 1.05rem 0.7875rem;
  text-transform: uppercase;
  letter-spacing: 3px; }
  .btn:hover {
    opacity: .6; }

label {
  position: relative;
  font-weight: 700; }

textarea,
input[type="text"],
input[type="email"] {
  margin-top: 6px;
  width: 100%;
  min-width: 400px;
  padding: 8px;
  background: #e5e5e5; }
  @media only screen and (max-width: 901px) {
    textarea,
    input[type="text"],
    input[type="email"] {
      min-width: 100%; } }

textarea {
  width: 104%;
  height: 120px; }

.g-recaptcha {
  margin-top: 6px; }

ul.errors {
  display: none; }

.has-error .form-control,
.has-error .custom-file-label {
  border-color: #d00 !important; }

.has-error .form-check-label {
  color: #d00; }

.has-error ul.errors {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: inherit; }

.has-error ul.errors > li {
  font-size: 80%;
  color: #d00;
  margin-top: .25rem; }

.alert {
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  position: fixed;
  top: 50px;
  width: 100%;
  z-index: 999;
  text-align: center;
  transition: opacity .3s; }

#contact-form .alert {
  top: 0;
  position: relative; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }

.__mTop {
  margin-top: 1.75rem; }

.__mTop__wee {
  margin-top: 0.875rem; }

.__mTop__wide {
  margin-top: 3.5rem; }

.__mTop__wider {
  margin-top: 5.25rem; }

.__mBottom {
  margin-bottom: 1.75rem; }

.__mBottom__wide {
  margin-bottom: 3.5rem; }

.__mBottom__wider {
  margin-bottom: 5.25rem; }

.__mV {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem; }

.__mV__wide {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem; }

.__mV__wee {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem; }

.__pTop {
  padding-top: 1.75rem; }

.__pTop__wide {
  padding-top: 3.5rem; }

.__pTop__wee {
  padding-top: 0.875rem; }

.__pBottom {
  padding-bottom: 1.75rem; }

.__pBottom__wide {
  padding-bottom: 3.5rem; }

.inline-block-item {
  display: inline-block; }

.inline-item {
  display: inline; }

.float__right {
  float: right; }

.float__left {
  float: left; }

.wrapper {
  box-sizing: border-box;
  margin: 0 auto; }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 0.875rem;
  padding-left: 0.875rem; }

.row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.4375rem;
  margin-left: -0.4375rem; }

.row.reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.col.reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.col {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  -webkit-flex-basis: auto;
  -moz-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -webkit-flex-basis: 0;
  -moz-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-1 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  -webkit-flex-basis: 8.33333%;
  -moz-flex-basis: 8.33333%;
  -ms-flex-preferred-size: 8.33333%;
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  -webkit-flex-basis: 16.66667%;
  -moz-flex-basis: 16.66667%;
  -ms-flex-preferred-size: 16.66667%;
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  -webkit-flex-basis: 25%;
  -moz-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%; }

.col-4 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  -webkit-flex-basis: 33.33333%;
  -moz-flex-basis: 33.33333%;
  -ms-flex-preferred-size: 33.33333%;
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  -webkit-flex-basis: 41.66667%;
  -moz-flex-basis: 41.66667%;
  -ms-flex-preferred-size: 41.66667%;
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  -webkit-flex-basis: 50%;
  -moz-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%; }

.col-7 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  -webkit-flex-basis: 58.33333%;
  -moz-flex-basis: 58.33333%;
  -ms-flex-preferred-size: 58.33333%;
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  -webkit-flex-basis: 66.66667%;
  -moz-flex-basis: 66.66667%;
  -ms-flex-preferred-size: 66.66667%;
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  -webkit-flex-basis: 75%;
  -moz-flex-basis: 75%;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%; }

.col-10 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  -webkit-flex-basis: 83.33333%;
  -moz-flex-basis: 83.33333%;
  -ms-flex-preferred-size: 83.33333%;
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  -webkit-flex-basis: 91.66667%;
  -moz-flex-basis: 91.66667%;
  -ms-flex-preferred-size: 91.66667%;
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%; }

.col-offset-0 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  margin-left: 0; }

.col-offset-1 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  margin-left: 8.33333%; }

.col-offset-2 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  margin-left: 16.66667%; }

.col-offset-3 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  margin-left: 25%; }

.col-offset-4 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  margin-left: 33.33333%; }

.col-offset-5 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  margin-left: 41.66667%; }

.col-offset-6 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  margin-left: 50%; }

.col-offset-7 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  margin-left: 58.33333%; }

.col-offset-8 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  margin-left: 66.66667%; }

.col-offset-9 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  margin-left: 75%; }

.col-offset-10 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  margin-left: 83.33333%; }

.col-offset-11 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  margin-left: 91.66667%; }

.col-offset-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  margin-left: 100%; }

.g-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  text-align: start; }

.g-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  text-align: center; }

.g-center img {
  margin: 0 auto; }

.g-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
  text-align: end; }

.g-top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.g-middle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.g-bottom {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.g-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.g-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.g-first {
  order: -1; }

.g-last {
  order: 1; }

@media only screen and (min-width: 0px) {
  .container {
    width: 100%; }
  .col-xs {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xs-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-xs-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-xs-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-xs-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-xs-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-xs-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-xs-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-xs-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-xs-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-xs-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-xs-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-xs-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-xs-offset-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 0; }
  .col-xs-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 8.33333%; }
  .col-xs-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 16.66667%; }
  .col-xs-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 25%; }
  .col-xs-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 33.33333%; }
  .col-xs-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 41.66667%; }
  .col-xs-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 50%; }
  .col-xs-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 58.33333%; }
  .col-xs-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 66.66667%; }
  .col-xs-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 75%; }
  .col-xs-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 83.33333%; }
  .col-xs-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 91.66667%; }
  .col-xs-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 100%; }
  .g-xs-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start; }
  .g-xs-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .g-xs-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end; }
  .g-xs-top {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .g-xs-middle {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .g-xs-bottom {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .g-xs-around {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .g-xs-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .g-xs-first {
    order: -1; }
  .g-xs-last {
    order: 1; } }

@media only screen and (min-width: 601px) {
  .container {
    width: 100%; }
  .col-sm {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-sm-offset-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 0; }
  .col-sm-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 8.33333%; }
  .col-sm-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 16.66667%; }
  .col-sm-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 25%; }
  .col-sm-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 33.33333%; }
  .col-sm-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 41.66667%; }
  .col-sm-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 50%; }
  .col-sm-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 58.33333%; }
  .col-sm-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 66.66667%; }
  .col-sm-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 75%; }
  .col-sm-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 83.33333%; }
  .col-sm-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 91.66667%; }
  .col-sm-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 100%; }
  .g-sm-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start; }
  .g-sm-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .g-sm-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end; }
  .g-sm-top {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .g-sm-middle {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .g-sm-bottom {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .g-sm-around {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .g-sm-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .g-sm-first {
    order: -1; }
  .g-sm-last {
    order: 1; } }

@media only screen and (min-width: 901px) {
  .container {
    width: 100%; }
  .col-md {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-md-offset-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 0; }
  .col-md-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 8.33333%; }
  .col-md-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 16.66667%; }
  .col-md-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 25%; }
  .col-md-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 33.33333%; }
  .col-md-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 41.66667%; }
  .col-md-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 50%; }
  .col-md-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 58.33333%; }
  .col-md-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 66.66667%; }
  .col-md-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 75%; }
  .col-md-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 83.33333%; }
  .col-md-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 91.66667%; }
  .col-md-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 100%; }
  .g-md-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start; }
  .g-md-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .g-md-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end; }
  .g-md-top {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .g-md-middle {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .g-md-bottom {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .g-md-around {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .g-md-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .g-md-first {
    order: -1; }
  .g-md-last {
    order: 1; } }

@media only screen and (min-width: 1201px) {
  .container {
    width: 100%; }
  .col-desktop {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-desktop-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-desktop-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-desktop-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-desktop-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-desktop-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-desktop-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-desktop-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-desktop-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-desktop-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-desktop-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-desktop-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-desktop-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-desktop-offset-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 0; }
  .col-desktop-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 8.33333%; }
  .col-desktop-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 16.66667%; }
  .col-desktop-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 25%; }
  .col-desktop-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 33.33333%; }
  .col-desktop-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 41.66667%; }
  .col-desktop-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 50%; }
  .col-desktop-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 58.33333%; }
  .col-desktop-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 66.66667%; }
  .col-desktop-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 75%; }
  .col-desktop-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 83.33333%; }
  .col-desktop-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 91.66667%; }
  .col-desktop-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 100%; }
  .g-desktop-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start; }
  .g-desktop-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .g-desktop-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end; }
  .g-desktop-top {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .g-desktop-middle {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .g-desktop-bottom {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .g-desktop-around {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .g-desktop-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .g-desktop-first {
    order: -1; }
  .g-desktop-last {
    order: 1; } }

@media only screen and (min-width: 1801px) {
  .container {
    width: 100%; }
  .col-lg {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-lg-offset-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 0; }
  .col-lg-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 8.33333%; }
  .col-lg-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 16.66667%; }
  .col-lg-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 25%; }
  .col-lg-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 33.33333%; }
  .col-lg-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 41.66667%; }
  .col-lg-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 50%; }
  .col-lg-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 58.33333%; }
  .col-lg-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 66.66667%; }
  .col-lg-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 75%; }
  .col-lg-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 83.33333%; }
  .col-lg-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 91.66667%; }
  .col-lg-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
    margin-left: 100%; }
  .g-lg-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start; }
  .g-lg-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .g-lg-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end; }
  .g-lg-top {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .g-lg-middle {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .g-lg-bottom {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .g-lg-around {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .g-lg-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .g-lg-first {
    order: -1; }
  .g-lg-last {
    order: 1; } }

#home {
  background-size: cover !important;
  background-position-y: inherit;
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  height: 600px;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%; }
  #home #home-page {
    width: 100%;
    height: 90%; }
    #home #home-page #home-logo {
      text-align: center;
      position: absolute;
      top: 15%;
      right: 7%;
      width: 390px; }
      @media only screen and (max-width: 901px) {
        #home #home-page #home-logo {
          display: none; } }
      #home #home-page #home-logo h1, #home #home-page #home-logo h2 {
        font-weight: 900;
        color: #191a15;
        font-size: 98px;
        font-size: 6.125rem;
        font-weight: 900;
        line-height: 58px;
        text-shadow: 0 .5px #555;
        margin-bottom: 20px; }
        #home #home-page #home-logo h1 span, #home #home-page #home-logo h2 span {
          display: block;
          font-size: .52em; }
      #home #home-page #home-logo.white h1,
      #home #home-page #home-logo.white h2 {
        color: #fff;
        text-shadow: none; }
      #home #home-page #home-logo h2 {
        margin-top: -10px;
        font-size: 2.1em;
        font-size: 29px;
        font-size: 1.8125rem;
        line-height: 30px;
        font-weight: 400; }
    #home #home-page .awards {
      display: block;
      position: absolute;
      bottom: 18%;
      right: 12%;
      text-align: center;
      color: #000;
      text-shadow: 0 1px #fff; }
      #home #home-page .awards .award-wreath {
        position: absolute;
        left: -45px;
        top: -24px;
        width: 290px; }
      #home #home-page .awards .recog, #home #home-page .awards .title, #home #home-page .awards .role, #home #home-page .awards .year {
        position: relative;
        top: 40px;
        left: 20px;
        text-transform: uppercase;
        font-weight: 400;
        width: 160px; }
      #home #home-page .awards .recog {
        font-size: 18px;
        font-size: 1.125rem;
        margin-bottom: 4px; }
      #home #home-page .awards .role {
        line-height: .9em;
        font-size: 28px;
        font-size: 1.75rem; }
      #home #home-page .awards .title {
        font-size: 24px;
        font-size: 1.5rem;
        font-style: italic;
        text-transform: none;
        line-height: .8em; }
        #home #home-page .awards .title span {
          font-weight: 200;
          font-size: .6em;
          text-transform: lowercase;
          font-style: normal; }
      #home #home-page .awards .year {
        font-weight: 200;
        font-size: 1.2em;
        margin-top: 4px; }
      #home #home-page .awards.white {
        color: #fff;
        text-shadow: none; }
        #home #home-page .awards.white .award-wreath svg {
          fill: white; }
    #home #home-page .quote_box {
      display: block;
      position: absolute;
      bottom: 14%;
      left: 10%;
      width: 400px;
      height: auto;
      background-color: rgba(255, 255, 255, 0.4);
      color: #000;
      padding: 20px;
      border-radius: 20px; }
      #home #home-page .quote_box .open_quote,
      #home #home-page .quote_box .close_quote {
        margin-right: 8px;
        float: left; }
        #home #home-page .quote_box .open_quote svg,
        #home #home-page .quote_box .close_quote svg {
          width: 40px;
          height: 40px; }
      #home #home-page .quote_box .close_quote {
        float: right;
        margin: -22px 0 -4px 8px; }
      #home #home-page .quote_box .quote_quote {
        font-size: 1.3em;
        font-weight: 400;
        text-shadow: 0 1px rgba(255, 255, 255, 0.3);
        line-height: 1.2em;
        font-style: italic; }
      #home #home-page .quote_box .quote_author {
        font-size: 1.7em;
        margin: 20px 5px 2px 0;
        text-align: right;
        width: 100%;
        text-shadow: 0 1px rgba(255, 255, 255, 0.5); }
      #home #home-page .quote_box .quote_bio {
        font-size: 1.4em;
        margin-right: 6px;
        text-align: right;
        width: 100%;
        text-shadow: 0 1px rgba(255, 255, 255, 0.5); }
      #home #home-page .quote_box.white {
        color: #fff; }
        #home #home-page .quote_box.white .quote_quote,
        #home #home-page .quote_box.white .quote_author,
        #home #home-page .quote_box.white .quote_bio {
          text-shadow: none; }
        #home #home-page .quote_box.white .open_quote svg,
        #home #home-page .quote_box.white .close_quote svg {
          fill: #fff; }
      @media only screen and (max-width: 901px) {
        #home #home-page .quote_box {
          display: none; } }
    #home #home-page .home-reel {
      float: right;
      position: absolute;
      bottom: 50%;
      right: 11%;
      width: auto; }
      @media only screen and (max-width: 1201px) {
        #home #home-page .home-reel {
          float: none;
          right: 0;
          left: 0;
          width: 100%;
          bottom: 65px; } }
      #home #home-page .home-reel h3 {
        text-align: center;
        text-shadow: 1px 1px #333;
        margin-top: 2px; }
        #home #home-page .home-reel h3 svg {
          width: 40px;
          height: 40px;
          top: -3px;
          position: relative; }
        #home #home-page .home-reel h3 a {
          text-decoration: none;
          color: #000; }
          #home #home-page .home-reel h3 a:hover {
            text-decoration: none;
            opacity: 0.6; }
      #home #home-page .home-reel.white h3 a {
        color: #fff; }
      #home #home-page .home-reel.white h3 svg {
        fill: #fff; }

#latest {
  margin-top: 600px;
  background: url(/assets/sysimages/bkgrd-latest.png) repeat;
  min-height: 1300px; }
  #latest h2 span {
    margin-left: 148px;
    width: 812px; }
  #latest .row {
    padding-top: 50px;
    padding-bottom: 40px;
    background: url(/assets/sysimages/line3.png) repeat-x 0 bottom; }
    #latest .row img {
      width: 100%; }
    #latest .row .imageLoader {
      margin-right: 20px;
      border: 4px solid #fff;
      -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4); }
      @media only screen and (max-width: 601px) {
        #latest .row .imageLoader {
          margin-right: 0;
          margin-bottom: 20px; } }
    #latest .row .full-height {
      min-height: 220px; }
    #latest .row .row-last {
      background: none; }

.no-line {
  background-image: none !important; }

.load-more-spinner {
  display: none; }

.load-more-btn {
  z-index: 12;
  cursor: pointer;
  text-align: center;
  border: 1px solid #000;
  padding: 12px 20px;
  text-transform: uppercase; }
  .load-more-btn:hover {
    background: rgba(0, 0, 0, 0.4);
    color: white; }

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-line-scale-party,
.la-line-scale-party > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.la-line-scale-party {
  display: block;
  font-size: 0;
  color: #fff; }

.la-line-scale-party.la-dark {
  color: #333; }

.la-line-scale-party > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor; }

.la-line-scale-party {
  width: 40px;
  height: 32px; }

.la-line-scale-party > div {
  width: 4px;
  height: 32px;
  margin: 2px;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
  -webkit-animation-name: line-scale-party;
  -moz-animation-name: line-scale-party;
  -o-animation-name: line-scale-party;
  animation-name: line-scale-party;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

.la-line-scale-party > div:nth-child(1) {
  -webkit-animation-duration: .43s;
  -moz-animation-duration: .43s;
  -o-animation-duration: .43s;
  animation-duration: .43s;
  -webkit-animation-delay: -.23s;
  -moz-animation-delay: -.23s;
  -o-animation-delay: -.23s;
  animation-delay: -.23s; }

.la-line-scale-party > div:nth-child(2) {
  -webkit-animation-duration: .62s;
  -moz-animation-duration: .62s;
  -o-animation-duration: .62s;
  animation-duration: .62s;
  -webkit-animation-delay: -.32s;
  -moz-animation-delay: -.32s;
  -o-animation-delay: -.32s;
  animation-delay: -.32s; }

.la-line-scale-party > div:nth-child(3) {
  -webkit-animation-duration: .43s;
  -moz-animation-duration: .43s;
  -o-animation-duration: .43s;
  animation-duration: .43s;
  -webkit-animation-delay: -.44s;
  -moz-animation-delay: -.44s;
  -o-animation-delay: -.44s;
  animation-delay: -.44s; }

.la-line-scale-party > div:nth-child(4) {
  -webkit-animation-duration: .8s;
  -moz-animation-duration: .8s;
  -o-animation-duration: .8s;
  animation-duration: .8s;
  -webkit-animation-delay: -.31s;
  -moz-animation-delay: -.31s;
  -o-animation-delay: -.31s;
  animation-delay: -.31s; }

.la-line-scale-party > div:nth-child(5) {
  -webkit-animation-duration: .74s;
  -moz-animation-duration: .74s;
  -o-animation-duration: .74s;
  animation-duration: .74s;
  -webkit-animation-delay: -.24s;
  -moz-animation-delay: -.24s;
  -o-animation-delay: -.24s;
  animation-delay: -.24s; }

.la-line-scale-party.la-sm {
  width: 20px;
  height: 16px; }

.la-line-scale-party.la-sm > div {
  width: 2px;
  height: 16px;
  margin: 1px;
  margin-top: 0;
  margin-bottom: 0; }

.la-line-scale-party.la-2x {
  width: 80px;
  height: 64px; }

.la-line-scale-party.la-2x > div {
  width: 8px;
  height: 64px;
  margin: 4px;
  margin-top: 0;
  margin-bottom: 0; }

.la-line-scale-party.la-3x {
  width: 120px;
  height: 96px; }

.la-line-scale-party.la-3x > div {
  width: 12px;
  height: 96px;
  margin: 6px;
  margin-top: 0;
  margin-bottom: 0; }

/*
 * Animation
 */
@-webkit-keyframes line-scale-party {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1); }
  50% {
    -webkit-transform: scaleY(0.3);
    transform: scaleY(0.3); }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1); } }

@-moz-keyframes line-scale-party {
  0% {
    -moz-transform: scaleY(1);
    transform: scaleY(1); }
  50% {
    -moz-transform: scaleY(0.3);
    transform: scaleY(0.3); }
  100% {
    -moz-transform: scaleY(1);
    transform: scaleY(1); } }

@-o-keyframes line-scale-party {
  0% {
    -o-transform: scaleY(1);
    transform: scaleY(1); }
  50% {
    -o-transform: scaleY(0.3);
    transform: scaleY(0.3); }
  100% {
    -o-transform: scaleY(1);
    transform: scaleY(1); } }

@keyframes line-scale-party {
  0% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1); }
  50% {
    -webkit-transform: scaleY(0.3);
    -moz-transform: scaleY(0.3);
    -o-transform: scaleY(0.3);
    transform: scaleY(0.3); }
  100% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1); } }

#resume {
  background-color: #000;
  color: #fff; }
  #resume h2, #resume h4, #resume h5 {
    color: #fff; }
  #resume h2 {
    text-shadow: none; }
    #resume h2 span {
      margin-left: 167px;
      width: 793px; }
  #resume a {
    color: #eee; }
  #resume p {
    font-size: 1.1em;
    margin: 2px 0; }
  #resume.white {
    background: url(/assets/sysimages/bkgrd-resume.png) repeat;
    color: #191a15; }
    #resume.white h2, #resume.white h4, #resume.white h5 {
      color: #191a15; }
    #resume.white a {
      color: #444; }
  #resume .content {
    padding-bottom: 50px; }
  #resume .resume-items {
    float: left;
    padding-bottom: 50px; }
  #resume .resume-sidebar {
    margin-top: 5em; }
    #resume .resume-sidebar img {
      margin-top: 20px; }
    #resume .resume-sidebar h5 {
      margin-top: 80px;
      font-size: 30px;
      font-size: 1.875rem;
      font-weight: 800;
      text-transform: uppercase; }
    #resume .resume-sidebar h6 {
      font-size: 22px;
      font-size: 1.375rem;
      font-weight: 800;
      margin-top: 14px; }
    #resume .resume-sidebar p {
      margin-bottom: 1.2em; }
    #resume .resume-sidebar .resume-reps {
      margin-bottom: 60px; }
      #resume .resume-sidebar .resume-reps p {
        margin: 0 0 6px 0; }
      #resume .resume-sidebar .resume-reps .imageLoader img {
        max-height: 200px;
        max-width: 200px; }
      #resume .resume-sidebar .resume-reps .imageLoader .imageLoader_previewImage {
        filter: none; }

ul.gallery {
  clear: both;
  margin: 40px 0 0;
  padding: 0;
  list-style-type: none; }
  ul.gallery li {
    padding: 0;
    margin: 0 4px 20px;
    float: left;
    background-color: #fff;
    border: 6px solid #fff;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    font-size: .9em;
    overflow: hidden;
    color: #191a15;
    max-width: 170px; }
    ul.gallery li span {
      display: block;
      font-size: .7em; }
  ul.gallery.gallery--captions li {
    height: 220px; }
    ul.gallery.gallery--captions li img {
      margin-bottom: 6px; }

h4.channel-link {
  margin: -10px 0 40px;
  float: right;
  text-transform: none;
  text-align: right; }
  h4.channel-link a {
    text-decoration: none;
    color: #fff;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; }
    h4.channel-link a:hover {
      text-decoration: underline; }

#media h2 span {
  margin-left: 138px;
  width: 822px; }

#photos h2 {
  color: #fff; }
  #photos h2 span {
    margin-left: 153px;
    width: 807px; }

#photos #photo-group {
  overflow: hidden; }

.white {
  color: #fff; }

.grid {
  max-width: 960px;
  /* clear fix */
  /* ---- .grid-item ---- */ }
  .grid .grid:after {
    content: '';
    display: block;
    clear: both; }
  .grid .grid-item {
    position: relative;
    float: left;
    width: 192px;
    height: 400px;
    margin: 5px auto; }
    .grid .grid-item .grid-item__interior {
      padding: 10px;
      background: #fff;
      height: 100%;
      width: 97%; }
      .grid .grid-item .grid-item__interior img {
        width: 100%;
        max-height: 160px; }
  .grid .grid-item > * {
    /* margin: 0;
    padding: 0;*/ }

#videos h2 span {
  margin-left: 142px;
  width: 818px; }

#contact, #footer {
  color: #fff;
  overflow: hidden;
  background-color: #000;
  position: relative;
  min-height: 920px; }
  #contact h2, #footer h2 {
    color: #fff;
    text-shadow: none; }
  #contact a, #contact h4, #footer a, #footer h4 {
    color: #e5e5e5; }
  #contact h5, #footer h5 {
    color: #fff;
    font-size: 1.4em;
    margin: -10px 0 30px 20px; }
  #contact img, #footer img {
    margin-top: 30px; }
    @media only screen and (max-width: 601px) {
      #contact img, #footer img {
        display: none; } }
  #contact p, #footer p {
    color: #fff;
    padding: 2px 0;
    margin: 0; }
  #contact.white, #footer.white {
    color: #000;
    background: url(/assets/sysimages/bkgrd-resume.png) repeat; }
    #contact.white h2, #contact.white h5, #footer.white h2, #footer.white h5 {
      color: #191a15; }
    #contact.white a, #contact.white h4, #footer.white a, #footer.white h4 {
      color: #333; }
    #contact.white p, #footer.white p {
      color: #000; }
  #contact .content, #footer .content {
    height: 100%; }
    #contact .content #copyright, #footer .content #copyright {
      position: absolute;
      bottom: 10%;
      right: 18%;
      font-size: .8em; }
  #contact .agency-logo, #footer .agency-logo {
    float: right;
    position: relative;
    top: -57px; }
  #contact #contact-form, #footer #contact-form {
    max-width: 600px;
    margin: 20px auto; }
    @media only screen and (max-width: 601px) {
      #contact #contact-form, #footer #contact-form {
        margin: 0 auto !important; } }

.mfp-content #contact-form {
  max-width: 600px !important;
  margin: 0 auto; }

#footer {
  min-height: 0;
  height: auto;
  padding: 2rem 2rem 2.25rem; }
  #footer #copyright {
    font-size: .8em;
    text-align: right; }

#mowerman {
  overflow: hidden;
  color: #fff; }
  #mowerman .mowerbox {
    width: 100%;
    max-height: 780px;
    display: block;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 80px 40px; }
    @media only screen and (max-width: 901px) {
      #mowerman .mowerbox {
        max-height: 100%; } }
    #mowerman .mowerbox .cover {
      border: 6px solid #fff;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
      width: 100%; }
      #mowerman .mowerbox .cover img {
        width: 100%; }
  #mowerman h6 {
    color: #fff;
    float: left;
    text-shadow: 0 1px #191a15;
    text-transform: none;
    line-height: 1.8em;
    text-align: justify;
    padding: 0 12%;
    font-weight: 400;
    font-family: droid-serif, serif;
    font-size: 30px;
    font-size: 1.875rem; }
  #mowerman .mower-gallery {
    clear: both;
    width: 100%;
    height: 292px;
    position: relative;
    margin: 80px 0 0 0; }
    #mowerman .mower-gallery .carousel .carousel-cell {
      width: 160px;
      height: 160px;
      margin-right: 10px; }
      #mowerman .mower-gallery .carousel .carousel-cell .carousel-image {
        min-width: 160px;
        height: 160px;
        border: 4px solid #fff; }
  #mowerman .full-screen-carousel {
    height: 660px; }
    #mowerman .full-screen-carousel .carousel-cell {
      width: 90%;
      height: 646px; }
      #mowerman .full-screen-carousel .carousel-cell .carousel-image {
        border: 4px solid #fff;
        margin: 0 auto;
        text-align: center; }
    #mowerman .full-screen-carousel .mower-caption {
      max-width: 800px;
      margin: 0 auto;
      padding: 12px;
      background: rgba(0, 0, 0, 0.8);
      color: #fff;
      text-align: left;
      position: relative;
      top: -44px; }
  #mowerman a {
    color: #dde5ba; }
    #mowerman a:hover {
      color: #a0a489;
      text-decoration: none; }
  #mowerman h2 {
    margin: 0 0 10px 20px;
    color: #fff;
    text-shadow: 0 1px #191a15; }
  #mowerman .mower-more {
    margin: 0 0 80px 20px;
    font-size: 1.6em;
    text-align: center; }
  #mowerman ul#mower-group li {
    list-style-type: none;
    width: 130px;
    height: 130px;
    margin: 0 5px 20px;
    float: left;
    background-color: #fff;
    border: 6px solid #fff;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4); }

#reel h2 span {
  margin-left: 130px;
  width: 830px; }

#reel .reel-row {
  margin-bottom: 2em;
  padding: 20px 0 10px;
  color: #191a15;
  text-shadow: 0 1px #fff; }
  #reel .reel-row .imageLoader {
    width: 100%;
    margin-right: 20px;
    margin-bottom: 20px;
    border: 8px solid #fff;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4); }
  #reel .reel-row p {
    font-size: 1.1em; }

.interior {
  margin-top: 100px; }
  .interior .content {
    padding: 20px; }
  .interior .item {
    margin: 1.5em 0;
    padding-bottom: .8em;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd; }
  .interior h1 {
    font-weight: 900;
    font-size: 48px;
    font-size: 3rem;
    color: #191a15;
    margin: 0 0 2rem; }
  .interior h2 {
    font-size: 30px;
    font-size: 1.875rem;
    margin: 1.5rem 0; }
  .interior h3 {
    font-size: 26px;
    font-size: 1.625rem;
    margin: 3rem 0 .5rem;
    text-transform: uppercase; }
  .interior h2 a {
    color: #8069d6; }
  .interior ul, .interior ol {
    margin-left: 2.5rem;
    line-height: 25px;
    margin-bottom: 4rem; }
  .interior ul li {
    list-style: disc; }
  .interior ol li {
    list-style: decimal; }
  .interior p {
    font-size: 16px;
    line-height: 30px;
    margin: 20px 0; }
  .interior p.meta {
    font-size: 13px; }
  .interior .button {
    background-color: #191a15;
    color: #fff;
    text-decoration: none;
    padding: 16px 20px 10px;
    margin: 20px 0;
    text-transform: uppercase;
    font-size: 20px;
    font-size: 1.25rem; }
    .interior .button:hover {
      background-color: #808080; }
  .interior hr {
    height: 2px;
    width: 100%;
    border-top: 2px solid #e5e5e5;
    margin: 60px auto;
    position: relative; }
  .interior blockquote {
    color: #333;
    font-style: italic;
    padding: 1rem;
    border-top: 5px solid #aaa;
    border-bottom: 5px solid #aaa;
    line-height: 30px;
    margin: 4rem auto;
    max-width: 60%; }
  .interior .blog p {
    color: #808080; }
  .interior .blog .blog-img {
    float: left;
    margin: 0 20px 20px 0; }
  .interior img.right {
    float: right;
    margin: 0 0 20px 20px; }
  .interior img.left {
    float: left;
    margin: 0 20px 20px 0; }
  .interior .prev_next ul {
    list-style: none;
    margin: 0;
    height: 40px; }
    .interior .prev_next ul li {
      width: 49.5%; }
      .interior .prev_next ul li.prev-post {
        float: left; }
      .interior .prev_next ul li.next-post {
        float: right;
        text-align: right; }

.blog-landing .item {
  border-top: 0; }

.nav-space {
  padding: 0 5px; }

.tippy-box[data-animation=fade][data-state=hidden] {
  opacity: 0; }

[data-tippy-root] {
  max-width: calc(100vw - 10px); }

.tippy-box {
  position: relative;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  outline: 0;
  transition-property: transform,visibility,opacity; }

.tippy-box[data-placement^=top] > .tippy-arrow {
  bottom: 0; }

.tippy-box[data-placement^=top] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top; }

.tippy-box[data-placement^=bottom] > .tippy-arrow {
  top: 0; }

.tippy-box[data-placement^=bottom] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom; }

.tippy-box[data-placement^=left] > .tippy-arrow {
  right: 0; }

.tippy-box[data-placement^=left] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left; }

.tippy-box[data-placement^=right] > .tippy-arrow {
  left: 0; }

.tippy-box[data-placement^=right] > .tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right; }

.tippy-box[data-inertia][data-state=visible] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11); }

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333; }

.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid; }

.tippy-content {
  position: relative;
  padding: 5px 9px;
  z-index: 1; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 1200px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/**
 * Simple fade transition,
 */
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out; }

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8; }

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0; }

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out; }

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1; }

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0; }

.white-popup-block {
  background-color: #fff;
  padding: 60px; }

/*
.flickity-button {
  background: #333;

  &:hover {
    background: #F90;
  }
}

.flickity-prev-next-button {
  width: 30px;
  height: 30px;

  &.previous {
    left: -40px;
  }

  &.next {
    right: -40px;
  }
}

.flickity-button-icon {
  fill: white;
}

.flickity-button:disabled {
  display: none;
}

.flickity-page-dots {
  bottom: 0;

  .dot {
    width: 12px;
    height: 12px;
    opacity: 1;
    background: transparent;
    border: 2px solid white;

    &.is-selected {
      background: white;
    }
  }
}*/
/*! Flickity v2.2.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -50px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  border-radius: 50%;
  opacity: 1;
  cursor: pointer;
  background: transparent;
  border: 2px solid white; }

.flickity-page-dots .dot.is-selected {
  background: white; }

.carousel-cell-image {
  transition: opacity 0.25s;
  opacity: 0; }

.carousel-cell-image.flickity-lazyloaded,
.carousel-cell-image.flickity-lazyerror {
  opacity: 1; }
