%cursive, .cursive  {
  font-family: 'bitter', serif;
}

%serif, .serif {
  font-family: 'alternate-gothic-atf', sans-serif;
}

%sansSerif, .sansSerif {
  font-family: 'open-sans', sans-serif;
}

em, .em {
  font-style: italic;
}

strong, .strong {
  font-weight: bold;
}


@mixin titleFont {
  @extend %cursive;
  font-weight: 900;
}

@mixin altTitleFont {
  @extend %serif;
}

@mixin bodyFont {
  @extend %sansSerif;
}

body {
  @include bodyFont;
}

h2 {
  @include altTitleFont;
  font-weight: 800;
  @include fontsize(53);
}

h3 {
  color:$c-dark;
  @include fontsize(35);
  font-weight:600;
  margin-bottom:10px;
  @include bodyFont;
}

h4 {
  color:$c-dark;
  @include fontsize(28);
  font-weight:800;
  text-transform:uppercase;
  margin:30px 0 0;
  padding:0;
  @include altTitleFont;
}