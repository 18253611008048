#mowerman {
  overflow: hidden;
  color: $c-white;

  .mowerbox {
    width: 100%;
    max-height:780px;
    display: block;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 80px 40px;

    @include mqSmallerThan(tabletLandscape) {
      max-height:100%;
    }

    .cover {
      border: 6px solid $c-white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
      width:100%;

      img {
        width:100%;
      }
    }
  }

  h6 {
    color: $c-white;
    float: left;
    text-shadow: 0 1px $c-dark;
    text-transform: none;
    line-height: 1.8em;
    text-align: justify;
    padding:0 12%;
    font-weight: 400;
    font-family: droid-serif, serif;
    @include fontsize(30);
  }

  .mower-gallery {
    clear: both;
    width: 100%;
    height: 292px;
    position: relative;
    margin: 80px 0 0 0;

    .carousel {
      .carousel-cell {
        width: 160px;
        height: 160px;
        margin-right: 10px;

        .carousel-image {
          min-width: 160px;
          height: 160px;
          border:4px solid $c-white;
        }

      }
    }
  }

  .full-screen-carousel {
    height:660px;

    .carousel-cell {
      width: 90%;
      height: 646px;

      .carousel-image {
        border:4px solid $c-white;
        margin: 0 auto;
        text-align:center;
      }
    }

    .mower-caption {
      max-width:800px;
      margin:0 auto;
      padding:12px;
      background:rgba(0,0,0,0.8);
      color:$c-white;
      text-align:left;
      position: relative;
      top:-44px;
    }
  }

  a {
    color: #dde5ba;

    &:hover {
      color: #a0a489;
      text-decoration: none;
    }
  }

  h2 {
    margin: 0 0 10px 20px;
    color: $c-white;
    text-shadow: 0 1px $c-dark;
  }

  .mower-more {
    margin: 0 0 80px 20px;
    font-size: 1.6em;
    text-align: center;
  }

  ul#mower-group li {
    list-style-type: none;
    width: 130px;
    height: 130px;
    margin: 0 5px 20px;
    float: left;
    background-color: $c-white;
    border: 6px solid $c-white;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  }
}