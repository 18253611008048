#contact, #footer {
  color:$c-white;
  overflow:hidden;
  background-color: $c-black;
  position:relative;
  min-height: 920px;

  h2 {
    color:$c-white;
    text-shadow:none;
  }

  a, h4 {
    color:$c-lightGray;
  }

  h5 {
    color:$c-white;
    font-size:1.4em;
    margin:-10px 0 30px 20px;
  }

  img {
    margin-top:30px;

    @include mqSmallerThan(tabletPortrait) {
      display: none;
    }
  }

  p {
    color:$c-white;
    padding:2px 0;
    margin:0;
  }

  &.white {
    color:$c-black;
    background:url(/assets/sysimages/bkgrd-resume.png) repeat;

    h2, h5 {
      color:$c-dark;
    }
    a, h4 {
      color:$c-gray;
    }
    p { color:$c-black; }
  }

  .content {
    height:100%;

    #copyright {
      position: absolute;
      bottom: 10%;
      right: 18%;
      font-size: .8em;
    }
  }

  .agency-logo {
    float:right;
    position: relative;
    top: -57px;
  }

  #contact-form {
    max-width:600px;
    margin: 20px auto;

    @include mqSmallerThan(tabletPortrait) {
      margin:0 auto !important;
    }
  }

}


.mfp-content #contact-form {
  max-width:600px !important;
  margin:0 auto;
}

#footer {
  min-height:0;
  height:auto;
  padding:2rem 2rem 2.25rem;

  #copyright {
    font-size: .8em;
    text-align:right;
  }
}