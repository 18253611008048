/*
 * Colors
 */

$c-white: #fff;
$c-black: #000;
$c-dark: #191a15;
$c-red: #d32e2e;
$c-tan: #a0a489;
$c-lightTan: #dde5ba;
$c-lighterTan: #d9dbd0;
$c-gray: #333;
$c-lighterGray: #aaa;
$c-lightGray: #e5e5e5;
$c-medGray: #808080;


/*
 * Share colors
 */
$facebookBlue: #537bbd;
$twitterBlue: #78cdf0;
$rssOrange: #f39200;
$emailRed: #eb5755;
$pinterestRed: #c8232c;
$youtubeRed: #ff0000;


/*
 * Opacity
 */
$overlayOpacity: 0.5;
$smokeOpacity: 0.7;



/*
 * Timing
 */

$transitionTime: .5s;
$fastTransitionTime: .2s;


/*
 * Typography
 */

$defaultFontSize: 14;
$smallFontSize: 12;


// Font-weights
$fw-bold: 900;
$fw-semibold: 700;
$fw-normal: 400;
$fw-light: 300;


/*
 * Spacing
 */
$navMargin: rems(14);
$defaultMargin: rems(14);
$wideMargin: rems($defaultFontSize*2);
$defaultPadding: rems(20);

$tinyFenceWidth: 480px;
$tightFenceWidth: 768px;
$fenceWidth: 960px;
$standardWidth: 1280px;
$bodyWidth: 1920px;

// c.f. https://medium.freecodecamp.com/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
$breakpoints: (
        standard: (min: 0px, max: 999999px),
        phone: (min: 0px, max: 600px),
        tabletPortrait: (min: 601px, max: 900px), // "sm" grid
        tabletLandscape: (min: 901px, max: 1200px), // "md" grid
        desktop: (min: 1201px, max: 1800px), // "desktop" grid
        bigass: (min: 1801px, max: 999999px), // "lg" grid
);


/*
 * Flexbox Grid
 */

$grid-columns: 12;

$gutter-width: rems($defaultFontSize);
$gutter-width-half: $gutter-width * .5;
$gutter-width-double: $gutter-width * 2;

$outer-margin: $gutter-width-double * 2;

$gridBreakpoints: (
        sm: map-get(map-get($breakpoints, 'tabletPortrait'), 'min'),
        md: map-get(map-get($breakpoints, 'tabletLandscape'), 'min'),
        desktop: map-get(map-get($breakpoints, 'desktop'), 'min'),
        lg: map-get(map-get($breakpoints, 'bigass'), 'min'),
);

$flexboxgrid-max-width: rems($bodyWidth);