.interior {
  margin-top: 100px;

  .content {
    padding:20px;
  }

  .item {
    margin: 1.5em 0;
    padding-bottom: .8em;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
  }

  h1 {
    @include titleFont;
    @include fontsize(48);
    color:$c-dark;
    margin:0 0 2rem;
  }

  h2 {
    @include fontsize(30);
    margin:1.5rem 0;
  }

  h3 {
    @include altTitleFont;
    @include fontsize(26);
    margin:3rem 0 .5rem;
    text-transform: uppercase;
  }

  h2 a {
    color: #8069d6;
  }

  ul, ol {
    margin-left:2.5rem;
    line-height:25px;
    margin-bottom:4rem;
  }
  ul li {
    list-style: disc;
  }
  ol li {
    list-style: decimal;
  }

  p {
    font-size: 16px;
    line-height:30px;
    margin: 20px 0;
  }

  p.meta {
    font-size: 13px;
  }

  .button {
    background-color: $c-dark;
    color:$c-white;
    text-decoration: none;
    padding:16px 20px 10px;
    margin:20px 0;
    @include altTitleFont;
    text-transform: uppercase;
    @include fontsize(20);

    &:hover {
      background-color: $c-medGray;
    }
  }

  hr {
    height:2px;
    width:100%;
    border-top: 2px solid $c-lightGray;
    margin:60px auto;
    position: relative;
  }

  blockquote {
    color: $c-gray;
    font-style: italic;
    padding:1rem;
    border-top:5px solid $c-lighterGray;
    border-bottom:5px solid $c-lighterGray;
    line-height: 30px;
    margin:4rem auto;
    max-width:60%;
  }

  .blog {
    p {
      color: $c-medGray;
    }

    .blog-img {
      float: left;
      margin: 0 20px 20px 0;
    }

  }

  img.right {
    float: right;
    margin: 0 0 20px 20px;
  }

  img.left {
    float: left;
    margin: 0 20px 20px 0;
  }

  .btn-read-more {
  }

  .prev_next {

    ul {
      list-style: none;
      margin: 0;
      height: 40px;

      li {
        width: 49.5%;

        &.prev-post {
          float: left;
        }

        &.next-post {
          float: right;
          text-align: right;
        }
      }

    }
  }

}

.blog-landing .item {
  border-top:0;
}

.nav-space { padding:0 5px; }