#home {
  background-size: cover !important;
  background-position-y: inherit;
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  height: 600px;
  position: fixed;
  top: 0;
  bottom:0;
  width: 100%;

  #home-page {
    width: 100%;
    height: 90%;

    #home-logo {
      text-align: center;
      position: absolute;
      top: 15%;
      right: 7%;
      width:390px;

      @include mqSmallerThan(tabletLandscape) {
        display: none;
      }

      h1, h2 {
        @include titleFont;
        color: $c-dark;
        @include fontsize(98);
        font-weight:900;
        line-height: 58px;
        text-shadow: 0 .5px #555;
        margin-bottom:20px;

        span {
          display: block;
          font-size: .52em;
        }
      }

      &.white h1,
      &.white h2 {
        color: $c-white;
        text-shadow:none;
      }

      h2 {
        margin-top: -10px;
        font-size: 2.1em;
        @include fontsize(29);
        line-height:30px;
        font-weight: 400;
      }
    }

    .awards {
      display: block;
      position: absolute;
      bottom: 18%;
      right: 12%;
      text-align: center;
      color: $c-black;
      text-shadow: 0 1px $c-white;

      .award-wreath {
        position: absolute;
        left:-45px;
        top:-24px;
        width:290px;
      }

      .recog, .title, .role, .year {
        position: relative;
        top: 40px;
        left: 20px;
        text-transform: uppercase;
        font-weight: 400;
        width: 160px;
        @include altTitleFont;
      }

      .recog {
        @include fontsize(18);
        margin-bottom:4px;
      }

      .role {
        line-height: .9em;
        @include fontsize(28);
      }

      .title {
        @include fontsize(24);
        font-style: italic;
        text-transform: none;
        line-height: .8em;

        span {
          font-weight: 200;
          font-size: .6em;
          text-transform: lowercase;
          font-style: normal;
        }
      }

      .year {
        font-weight: 200;
        font-size: 1.2em;
        margin-top: 4px;
      }

      &.white {
        color:$c-white;
        text-shadow:none;

        .award-wreath svg {
          fill:white;
        }
      }
    }

    .quote_box {
      display: block;
      position: absolute;
      bottom: 14%;
      left: 10%;
      width: 400px;
      height: auto;
      background-color: rgba(255, 255, 255, .4);
      color: $c-black;
      padding: 20px;
      border-radius: 20px;

      .open_quote,
      .close_quote {
        margin-right: 8px;
        float: left;

        svg {
          width:40px;
          height:40px;
        }
      }

      .close_quote {
        float: right;
        margin: -22px 0 -4px 8px;
      }

      .quote_quote {
        font-size: 1.3em;
        font-weight: 400;
        text-shadow: 0 1px rgba(255, 255, 255, .3);
        line-height:1.2em;
        font-style: italic;
      }

      .quote_author {
        font-size: 1.7em;
        margin: 20px 5px 2px 0;
        text-align: right;
        width: 100%;
        text-shadow: 0 1px rgba(255, 255, 255, .5);
      }

      .quote_bio {
        font-size: 1.4em;
        margin-right: 6px;
        text-align: right;
        width: 100%;
        text-shadow: 0 1px rgba(255, 255, 255, .5);
      }

      &.white {
        color:$c-white;

        .quote_quote,
        .quote_author,
        .quote_bio {
          text-shadow:none;
        }

        .open_quote,
        .close_quote {
          svg {
            fill:$c-white;
          }
        }
      }

      @include mqSmallerThan(tabletLandscape) {
        display: none;
      }
    }

    .home-reel {
      float:right;
      position: absolute;
      bottom:50%;
      right:11%;
      width:auto;

      @include mqSmallerThan(desktop) {
        float:none;
        right:0;
        left:0;
        width:100%;
        bottom:65px;
      }

      h3 {
        text-align: center;
        text-shadow:1px 1px $c-gray;
        margin-top: 2px;

        svg {
          width:40px;
          height:40px;
          top:-3px;
          position: relative;
        }

        a {
          text-decoration: none;
          color:$c-black;

          &:hover {
            text-decoration: none;
            opacity:0.6;
          }
        }
      }

      &.white {
        h3 {
          a { color:$c-white; }
          svg { fill:$c-white; }
        }
      }
    }

  }
}