ul.gallery {
  clear:both;
  margin:40px 0 0;
  padding:0;
  list-style-type:none;

  li {
    padding:0;
    margin:0 4px 20px;
    float:left;
    background-color:$c-white;
    border:6px solid $c-white;
    -webkit-box-shadow:0 2px 5px rgba(0,0,0,0.4);
    -moz-box-shadow:0 2px 5px rgba(0,0,0,0.4);
    box-shadow:0 2px 5px rgba(0,0,0,0.4);
    font-size:.9em;
    overflow:hidden;
    color:$c-dark;
    max-width:170px;

    span {
      display:block;
      font-size:.7em;
    }

  }

  &.gallery--captions {
    li {
      height: 220px;

      img {
        margin-bottom:6px;
      }
    }
  }
}

h4.channel-link {
  margin:-10px 0 40px;
  float:right;
  text-transform: none;
  text-align: right;

  a {
    text-decoration: none;
    color:#fff;
    text-shadow:
            -1px -1px 0 #000,
            1px -1px 0 #000,
            -1px 1px 0 #000,
            1px 1px 0 #000;

    &:hover {
      text-decoration: underline;
    }
  }
}

#media {
  h2 {
    span {
      margin-left: 138px;
      width: 822px;
    }
  }
}

