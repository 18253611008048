.page {
  z-index:0;
}
.content {
  width:100%;
  max-width:960px;
  margin:0 auto 30px;
  z-index:10;
}

.section {
  border-top:1px solid $c-dark;
  clear:both;
  height:auto;
  overflow-y:auto;
  overflow-x:hidden;
  position:relative;
  color:$c-dark;
  padding:90px 20px 0;

  h2 {
    position: relative;
    color: $c-dark;
    text-transform: uppercase;
    @include fontsize(53);
    font-weight: 600;
    text-shadow: 0 1px $c-lighterTan;
    width: 100%;
    margin-bottom: 20px;

    span {
      background: url(/assets/sysimages/line1.png) repeat-x;
      height: 8px;
      float: left;
      position: relative;
      top: -32px;
    }
  }

  p {
    font-size:1.3em;
    line-height:1.4em;
    margin:14px 0;
  }

  a {
    position:relative;
  }
}

#social {
  position: fixed;
  bottom:0;
  right:10%;
  height:58px;
  background-color:#191a15;
  background-color:rgba(0,0,0,0.4);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin:0;
  padding:4px 8px 10px;

  ul {
    list-style:none;
    width:100%;
    margin:0;
    padding:0;

    li {
      float:left;
      padding:5px;

      &.social {
        height:36px;
        width:36px;
        top:4px;
        position: relative;
        margin-left:5px;

        a {
          display:block;
          width:34px;
          height:34px;

          svg {
            width:30px;
            height:30px;
          }
        }

        a#facebook { background-position: 0 0; }
        a#twitter { background-position: -34px 0; }
        a#youtube { background-position: -68px 0; }

        &:hover {
          opacity:0.6;
        }
      }
    }

    li + li {
      margin-top:0;
    }
  }
}

.videoThumb {
  position: relative;
  display: inline-block;
  width:100%;
}

.start-btn {
  z-index:100;
  position:absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
  opacity: 0.4;

  svg {
    height: 50px;
    width: 50px;
    fill:$c-white;
  }
}

.email-icon {
  svg {
    width: 25px;
    height: 25px;
    margin-top: -6px;
  }
}
.email-icon-white {
  svg {
    fill:$c-white;
    margin-right: 5px;
  }
}