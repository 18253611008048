#resume {
  background-color: $c-black;
  color:$c-white;

  h2, h4, h5 { color:$c-white; }

  h2 {
    text-shadow:none;

    span {
      margin-left: 167px;
      width: 793px;
    }
  }

  a { color:#eee; }

  p {
    font-size:1.1em;
    margin:2px 0;
  }

  &.white {
    background:url(/assets/sysimages/bkgrd-resume.png) repeat;
    color:$c-dark;

    h2, h4, h5 { color:$c-dark; }

    a { color:#444; }
  }

  .content { padding-bottom:50px; }

  .resume-items {
    float:left;
    padding-bottom:50px;
  }

  .resume-sidebar {
    margin-top:5em;

    img { margin-top:20px; }

    h5 {
      margin-top:80px;
      @include altTitleFont;
      @include fontsize(30);
      font-weight:800;
      text-transform: uppercase;
    }

    h6 {
      @include altTitleFont;
      @include fontsize(22);
      font-weight:800;
      margin-top:14px;
    }

    p {
      margin-bottom:1.2em;
    }

    .resume-reps {
      margin-bottom:60px;

      p {
        margin:0 0 6px 0;
      }

      .imageLoader {
        img {
          max-height: 200px;
          max-width: 200px;
        }
        .imageLoader_previewImage {
          filter:none;
        }
      }
    }

  }

}