#nav-container {
  z-index: 10;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  background-color: $c-dark;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);

  nav {
    position: relative;
    max-width: 960px;
    width:100%;
    margin: 14px auto 0;

    ul#mainNav {
      list-style: none;
      display: inline;

      li {
        float: left;
        @include fontsize(26);
        margin: 2px 14px 0;
        text-transform: uppercase;
        @extend %serif;
        font-weight:600;

        a {
          color: $c-white;
          text-decoration: none;
          outline: none;

          &:hover {
            color: $c-tan;
            text-decoration: none;
          }

          &.active {
            color: $c-lightTan;
          }
        }

        &:first-child {
          @include mqMin('1018px') {
            margin-left: 0;
          }
        }
      }

      @include mqSmallerThan(tabletLandscape) {
        left:-999em;
        position: absolute;
        top:36px;
        background-color: rgba(0,0,0,0.8);
        width:100%;
        padding:20px 20px;

        li {
          float:none;
          padding:5px;
          margin:0;
        }
      }

      &.menu-active {
        left:0;
      }
    }
    

    #logo {
      background:url(/assets/sysimages/bkgrd-sub-header.png) no-repeat;
      width:250px;
      height:50px;
      position:absolute;
      top:-14px;
      right:0;
      text-align:center;
      opacity:0;
      transition: opacity 0.5s;

      @include mqSmallerThan(tabletLandscape) {
        right:30px;
      }

      a {
        line-height:1.8em;
        height:50px;
        @include fontsize(28);
        font-weight:800;
        @extend %cursive;
        color:$c-white;
        text-decoration:none;
        outline:none;

        &:hover {
          color:$c-tan;
          text-decoration:none;
        }
      }
    }

    .mobileNav--toggle {
      display: none;
      position: absolute;
      top: -18px;
      margin: 0 auto;
      text-align: center;
      cursor: pointer;
      width: 60px;
      height: 60px;
      transition: transform 0.2s ease-in-out;

      a {
        display: block;
        position: absolute;
        width: 100%;
        height: 60px;
        padding-top: 7px;
        transition: transform 0.2s ease-in-out;
      }

      @include mqSmallerThan(tabletLandscape) {
        display: block;
      }
    }

    #mobileNav--toggleIcon {
      padding-left: 13px;
      padding-top: 28px;

      span, span:before, span:after {
        cursor: pointer;
        border-radius: 1px;
        height: 3px;
        width: 35px;
        background: $c-white;
        position: absolute;
        display: block;
        content: '';
      }

      span:before {
        top: -10px;
      }

      span:after {
        bottom: -10px;
      }
    }

    #mobileNav--toggleIcon span, #mobileNav--toggleIcon span:before, #mobileNav--toggleIcon span:after {
      transition: all 200ms ease-in-out;
    }

    #mobileNav--toggleIcon.nav-active span {
      background-color: transparent;
    }

    #mobileNav--toggleIcon.nav-active span:before, #mobileNav--toggleIcon.nav-active span:after {
      top: 0;
    }

    #mobileNav--toggleIcon.nav-active span:before {
      transform: rotate(45deg);
    }

    #mobileNav--toggleIcon.nav-active span:after {
      transform: rotate(-45deg);
    }
  }
}
